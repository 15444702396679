<template>
  <div class="">
    <van-sticky>
      <!-- <van-search value="请选择筛查条件"
        @click="searchClick"
        shape="round"
        background="#ffffff"
        disabled>
      </van-search>
      <van-popup v-model="searchShow"
        position="top"
        round>
        <div>

          <van-field v-model="inTime"
            placeholder="请选择入住时间"
            readonly
            label="入住时间"
            @click="inTimeClick"
            name="inTime" />
          <van-calendar v-model="inTimeShow"
            type="range"
            @confirm="onInTime"
            color="#1989fa"
            :min-date="minDate"
            :max-date="maxDate" />
          <van-field v-model="leaveTime"
            placeholder="请选择退房时间"
            readonly
            label="退房时间"
            @click="leaveTimeClick"
            name="leaveTime" />
          <van-calendar v-model="leaveTimeShow"
            type="range"
            @confirm="onLeaveTime"
            :min-date="minDate"
            :max-date="maxDate"
            color="#1989fa" />
          <van-field v-model="filters.roomCode"
            placeholder="请输入房号"
            label="房号"
            name="roomCode" />
          <van-field v-model="filters.name"
            placeholder="请输入姓名"
            label="姓名"
            name="name" />
          <van-field v-model="filters.mobile"
            placeholder="请输入手机号"
            label="手机号"
            name="mobile" />
          <div class="filtersBox">
            <div class="labelClass"
              style="padding: 10px 6px;">
              <div>
                性别
              </div>
            </div>
            <div style="padding: 10px 14px 6px 5px;">
              <van-radio-group v-model="filters.sex"
                @change="sexChange"
                direction="horizontal">
                <van-radio name="1"
                  class="radio">男</van-radio>
                <van-radio name="2"
                  class="radio">女</van-radio>
              </van-radio-group>
            </div>
          </div>
          <van-field v-model="filters.nationDesc"
            label="民族"
            placeholder="请选择民族"
            @click="nationPicker = true"
            readonly="readonly"
            clickable />
          <van-popup v-model="nationPicker"
            position="bottom">
            <van-picker show-toolbar
              :columns="nationList"
              value-key="Name"
              @confirm="nationQr"
              @cancel="nationPicker = false" />
          </van-popup>
          <div class="filtersBox">
            <div class="labelClass"
              style="padding: 10px 6px;">
              <div>
                退房
              </div>
            </div>
            <div style="padding: 10px 14px 6px 5px;">
              <van-radio-group v-model="filters.leaved"
                @change="leavedChange"
                direction="horizontal">
                <van-radio name="1"
                  class="radio">是</van-radio>
                <van-radio name="2"
                  class="radio">否</van-radio>
              </van-radio-group>
            </div>
          </div>
          <div class="filtersBox">
            <div class="labelClass"
              style="padding: 10px 6px;">
              <div>
                登记类型
              </div>
            </div>
            <div style="padding: 10px 14px 6px 5px;">
              <van-radio-group v-model="filters.enrolCate"
                @change="enrolCateChange"
                direction="horizontal">
                <van-radio name="1"
                  class="radio">民宿登记</van-radio>
                <van-radio name="2"
                  class="radio">旅客上报</van-radio>
              </van-radio-group>
            </div>
          </div>
          <div style="display: flex;margin-bottom: 10px;justify-content: space-around;">
            <button @click="resetSubmit"
              class="buttonStyle"
              style="background-color: #ff976a;">重 置</button>
            <button @click="filtersSubmit"
              class="buttonStyle">搜 索</button>
          </div>
        </div>
      </van-popup> -->
      <van-search
        v-model="filters.roomCode"
        placeholder="请输入房间号"
        shape="round"
        background="#ffffff"
        @search="onSearch"
        @blur="onBlur"
      >
      </van-search>
    </van-sticky>
    <div style="margin: 3%">
      <div class="tsrq">
        <span style="border-color: #3e83e9">空闲</span>
        <span style="border-color: #11e67b">在住</span>
        <!-- <span style="border-color: #8a8a8a">禁用</span> -->
        <div style="color: #8a8a8a; font-size: 15px">
          <div style="margin: 5px 0">
            待确认的预订房间数：{{ roomNum.waitRoomNum }}
          </div>
          <div>待入住的预订房间数：{{ roomNum.liveRoomNum }}</div>
        </div>
      </div>

      <!-- <div class="tsrq">
        <div></div>
      </div> -->
      <div>
        <div v-for="item in list" :key="item.index">
          <div class="box" @click="addGuest(item)" :class="'box' + item.Status">
            <div v-if="item.Status == 2 && item.GuestNum != null">
              <span v-if="item.GuestNum != null && item.GuestNum != ''">
                已住 {{ item.GuestNum }} 人</span
              >
            </div>
            <div style="font-size: 18px; font-weight: 550">
              {{ item.RoomCode }}
            </div>
            <div v-if="item.Status == 2 && item.GuestName != null">
              <span v-if="item.GuestName != null && item.GuestName != ''">
                {{ item.GuestName }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="center"
        v-for="item in list"
        :key="item.index">
        <div class="list">
          <div class="title">姓名:{{item.Name}}</div>
          <div class="flex">
            <text>性别:{{item.SexDesc}}</text>
            <text>身份证号:{{item.IdCard}}</text>
          </div>
          <div class="flex">
            <text>手机号:{{item.Mobile}}</text>
            <text>房号:{{item.RoomCode}}</text>
          </div>
          <div class="flex">
            <text>入住时间:{{item.InTime}}</text>
          </div>

          <div style="padding:5px 10px">住址:{{item.Address}}</div>
          <div style="padding:5px 10px"
            v-if="item.Leaved == 1">
            <text>退房时间:{{item.LeaveTime}}</text>
          </div>
          <div style="padding: 10px 20px 15px;"
            v-if="item.CantonCode != null">省市区:{{item.CantonName}}</div>
          <div style="padding: 10px 20px 15px;"
            v-else>省市区:</div>
          <div class="Status"
            style="background: #02c08f;"
            v-if="item.Leaved == 1">未退房</div>
          <div class="Status"
            style="background: #e6776f;"
            v-if="item.Leaved == 2">已退房</div>

        </div>
      </div> -->
      <div v-if="list.length == 0">
        <van-empty description="暂无数据" />
      </div>
    </div>
    <!-- <div style="position: fixed;bottom: 15px;width:100%;">
      <div style="width:100%; text-align: center;">
        <van-button class="button"
          @click="addGuest()"
          style="width:95%;"
          round
          type="info">添加旅客</van-button>
      </div>
    </div> -->
  </div>
</template>

<script>
import { GetCodeEntrysList } from "@/api/wangge";
import { HGetMyUseHotelRoomList, HGetMyHotelRoomNum } from "@/api/shop";
import { setOpenId, getOpenId } from "@/utils/auth";
export default {
  data() {
    return {
      searchShow: false, //筛查添加弹窗
      inTimeShow: false, //入住时间弹窗
      leaveTimeShow: false, //退房时间弹窗
      nationPicker: false, //民族弹窗
      inTime: "", //入住时间范围
      leaveTime: "", //退房时间范围
      nationList: [], //民族列表
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(2026, 0, 31),
      filters: {
        // startInTime: '',
        // endInTime: '',
        // startLeaveTime: '',
        // endLeaveTime: '',
        // roomCode: '',
        // name: '',
        // sex: '',
        // nationCode: '',
        // nationDesc: '',
        // cantonCode: '',
        // mobile: '',
        // leaved: '',
        // enrolCate: '',
        openID: "",
        hotelId: "",
        roomCode: "",
        // page: 1,
        // limit: 10,
      }, //搜索表单
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      list: {},
      roomNum: {},
    };
  },
  mounted() {
    this.zidianlist();
    this.filters.hotelId = this.$route.params.hotelId;
    this.getList(undefined);
    this.getMyHotelRoomNum();
  },

  methods: {
    // 获取待确认的预订房间数和待入住的预订房间数
    getMyHotelRoomNum() {
      HGetMyHotelRoomNum({
        hotelId: this.$route.params.hotelId,
        openID: getOpenId(),
      }).then((res) => {
        if (res.data.code == 0) {
          this.roomNum = res.data;
        }
      });
    },
    handleDoubleClick() {
      console.log("123");
      // 在这里编写处理双击事件的代码
    },
    onSearch(val) {
      this.filters.roomCode = val;
      this.list = {};
      this.getList(undefined);
    },
    onBlur(event) {
      this.filters.roomCode = event.target.value;
      this.list = {};
      this.getList(undefined);
    },
    // 字典查询
    zidianlist: function () {
      GetCodeEntrysList({ kind: "3" }).then((res) => {
        this.nationList = res.data.data;
      });
    },
    // 点击查看更多条件
    searchClick() {
      this.searchShow = true;
    },
    // 点击入住时间
    inTimeClick() {
      this.inTimeShow = true;
    },
    // 选中入住时间
    onInTime(event) {
      const [start, end] = event;
      this.inTimeShow = false;
      this.inTime = `${this.formatDate(start)}  ~  ${this.formatDate(end)}`;
      this.filters.startInTime = this.formatDate(start);
      this.filters.endInTime = this.formatDate(end);
    },
    // 点击退房时间
    leaveTimeClick() {
      this.leaveTimeShow = true;
    },
    // 选中退房时间
    onLeaveTime(event) {
      const [start, end] = event;
      this.leaveTimeShow = false;
      this.leaveTime = `${this.formatDate(start)}  ~  ${this.formatDate(end)}`;
      this.filters.startLeaveTime = this.formatDate(start);
      this.filters.endLeaveTime = this.formatDate(end);
    },
    //性别
    sexChange(val) {
      this.filters.sex = val;
    },
    // 退房
    leavedChange(val) {
      this.filters.leaved = val;
    },
    // 登记类型
    enrolCateChange(val) {
      this.filters.enrolCate = val;
    },
    // 民族确认
    nationQr(val) {
      this.filters.nationCode = val.Coding;
      this.filters.nationDesc = val.Name;
      this.nationPicker = false;
    },
    // 重置
    resetSubmit() {
      this.inTime = "";
      this.leaveTime = "";
      this.filters = {
        startInTime: "",
        endInTime: "",
        startLeaveTime: "",
        endLeaveTime: "",
        roomCode: "",
        name: "",
        sex: "",
        nationCode: "",
        nationDesc: "",
        cantonCode: "",
        mobile: "",
        leaved: "",
        enrolCate: "",
        page: 1,
        limit: 10,
      };
    },
    // 搜索
    filtersSubmit() {
      console.log(this.filters);
      this.getList();
    },
    // 获取旅客列表
    getList(row) {
      this.filters.openID = getOpenId();
      HGetMyUseHotelRoomList(this.filters).then((res) => {
        if (res.data.code == 0) {
          if (res.data.count == 0) {
            this.finished = true;
            this.list = {};
          } else {
            this.list = res.data.data;
            this.total = res.data.count;
            this.finished = true;
          }
        }
      });
    },
    // 添加旅客信息
    addGuest(val) {
      // 直接添加旅客信息
      if (val.Status == 1) {
        var url =
          "/hotels/myHotelsList/myGuestList/addGuest/0/" +
          this.$route.params.hotelId +
          "/" +
          val.RoomId +
          "/0";
        this.$router.push(url);
      }

      // 已有人在住 在添加
      if (val.Status == 2) {
        var url =
          "/hotels/myHotelsList/myGuestList/roomGuestList/" +
          val.RoomCode +
          "/" +
          this.$route.params.hotelId +
          "/" +
          val.RoomId;
        this.$router.push(url);
      }
    },
    // 时间格式化
    formatDate(date) {
      var date = new Date(date);
      let year = date.getFullYear(); //年
      let month = date.getMonth() + 1; //月
      month = month.toString().padStart(2, "0"); // 当小于10时，显示为01.02.03
      let day = date.getDate(); //日
      day = day.toString().padStart(2, "0"); // 当小于10时，显示为01.02.03
      return year + "-" + month + "-" + day;
    },
  },
};
</script>
<style scoped>
.labelClass {
  font-size: 14px;
  font-weight: 500;
  width: 86px;
  color: #646566 !important;
}
.radio {
  font-size: 14px;
  color: #646566 !important;
}
.buttonStyle {
  width: 45%;
  border-radius: 8px;
  background: #1989fa;
  color: white;
  height: 35px;
  line-height: 35px;
  border: 0px solid;
}
.filtersBox {
  display: flex;
  border-bottom: 1px solid #ebedf085;
  margin: 0px 10px;
}
.list {
  position: relative;
  width: 90%;
  margin: 10px 5% 0px;
  background: #fff;
  border-radius: 8px;
  font-size: 12px;
  color: #333;
  box-shadow: 0 0 5px #dadada;
}

.list .title {
  padding: 10px 20px 5px;
  font-weight: 700;
  font-size: 15px;
  color: #000;
  letter-spacing: 2px;
}

.list .flex {
  display: flex;
  font-size: 12px;
  color: #333;
  padding: 10px 20px 5px;
}

.list .flex text:nth-child(1) {
  width: 40%;
}

.list .flex text:nth-child(2) {
  width: 60%;
}

.list .Status {
  position: absolute;
  top: 0px;
  right: 10px;
  background: #4b8cff;
  padding: 5px 0px;
  color: #fff;
  width: 62px;
  text-align: center;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.tsrq {
  font-size: 16px;
  margin: 10px 25px 5px 10px;
}
.tsrq span {
  border-left-color: #fff;
  border-left-width: 15px;
  border-left-style: solid;
  padding-left: 5px;
  line-height: 15px;
  display: inline-block;
  margin-right: 10px;
}
.box {
  width: 43%;
  border-radius: 5px;
  float: left;
  margin: 1%;
  background: #3e83e9;
  min-height: 80px;
  padding: 8px;
  color: white;
  display: grid;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.box2 {
  background: #11e67b;
}
.box3 {
  background: #8a8a8a;
}
</style>